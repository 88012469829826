import React, { useState } from "react";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Table,
  Row,
  Col,
  DatePicker,
  Modal,
  Watermark
} from "antd";
import "./invoice.css";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import logo from "../../assets/abcd.png";
import sign from "../../assets/signn.png";

const Invoice = () => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [invoiceData, setInvoiceData] = useState();
  const [invoiceDate, setInvoiceDate] = useState();
  const onFinish = (values) => {
    setInvoiceData(values);
    setVisible(true);
    form.resetFields();
  };
  console.log("Received values of form:", invoiceData);
  const handleCancel = () => {
    setVisible(false);
  };
  //   const SaveAsPDFHandler = () => {
  //     const dom = document.getElementById("print");
  //     toPng(dom)
  //       .then((dataUrl) => {
  //         const img = new Image();
  //         img.crossOrigin = "annoymous";
  //         img.src = dataUrl;
  //         img.onload = () => {
  //           // Initialize the PDF.
  //           const pdf = new jsPDF({
  //             orientation: "portrait",
  //             unit: "in",
  //             format: [5.5, 8.5],
  //           });

  //           // Define reused data
  //           const imgProps = pdf.getImageProperties(img);
  //           const imageType = imgProps.fileType;
  //           const pdfWidth = pdf.internal.pageSize.getWidth();

  //           // Calculate the number of pages.
  //           const pxFullHeight = imgProps.height;
  //           const pxPageHeight = Math.floor((imgProps.width * 8.5) / 5.5);
  //           const nPages = Math.ceil(pxFullHeight / pxPageHeight);

  //           // Define pageHeight separately so it can be trimmed on the final page.
  //           let pageHeight = pdf.internal.pageSize.getHeight();

  //           // Create a one-page canvas to split up the full image.
  //           const pageCanvas = document.createElement("canvas");
  //           const pageCtx = pageCanvas.getContext("2d");
  //           pageCanvas.width = imgProps.width;
  //           pageCanvas.height = pxPageHeight;

  //           for (let page = 0; page < nPages; page++) {
  //             // Trim the final page to reduce file size.
  //             if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
  //               pageCanvas.height = pxFullHeight % pxPageHeight;
  //               pageHeight = (pageCanvas.height * pdfWidth) / pageCanvas.width;
  //             }
  //             // Display the page.
  //             const w = pageCanvas.width;
  //             const h = pageCanvas.height;
  //             pageCtx.fillStyle = "white";
  //             pageCtx.fillRect(0, 0, w, h);
  //             pageCtx.drawImage(img, 0, page * pxPageHeight, w, h, 0, 0, w, h);

  //             // Add the page to the PDF.
  //             if (page) pdf.addPage();

  //             const imgData = pageCanvas.toDataURL(`image/${imageType}`, 1);
  //             pdf.addImage(imgData, imageType, 0, 0, pdfWidth, pageHeight);
  //           }
  //           // Output / Save
  //           pdf.save(`invoice.pdf`);
  //         };
  //       })
  //       .catch((error) => {
  //         console.error("oops, something went wrong!", error);
  //       });
  //   };

  const printDiv = () => {
    const divToPrint = document.getElementById("print-me");
    html2canvas(divToPrint, { backgroundColor: "white" }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 10, 10, 180, 150);
      pdf.save("print-div.pdf");
    });
  };
  const columns = [
    {
      title: "SR No.",
      dataIndex: "serial",
      render: (text, record, index) => index + 1,
    },
    {
      title: "HSN Code",
      dataIndex: "hsnCode",
    },
    {
      title: "Product Name",
      dataIndex: "description",
    },
    {
      title: "Product QTY",
      dataIndex: "quantity",
    },
    {
      title: "Price",
      dataIndex: "price",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (text, record) => <span>{record.price * record.quantity}</span>,
    },
  ];
  const total = invoiceData?.users.reduce((acc, record) => {
    return acc + record.quantity * record.price;
  }, 0);

  let discountAmmount = parseFloat(
    (total * (invoiceData?.discount / 100))?.toFixed(2)
  );
  let igstAmmount = parseFloat((total * (invoiceData?.igst / 100))?.toFixed(2));
  let cgstAmmount = parseFloat((total * (invoiceData?.cgst / 100))?.toFixed(2));
  let sgstAmmount = parseFloat((total * (invoiceData?.sgst / 100))?.toFixed(2));
  let totalTax = parseFloat(
    (igstAmmount + cgstAmmount + sgstAmmount)?.toFixed(2)
  );


  return (
    <div >
    <h2 className="text-2xl text-cyan-900 text-center font-semibold font-serif">Invoice</h2>
      <Form
        form={form}
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        autoComplete="off"
      >
      <div className="md:px-16 ">
      <Row gutter={12} className="p-4 bg-slate-900 opacity-90 rounded-t-md">
          <Col span={12}>
            <h3 className="text-white">Invoice Date</h3>
            <Form.Item
              name="invoiceDate"
              rules={[
                {
                  required: true,
                  message: "Select Invoice date",
                },
              ]}
            >
              <DatePicker
                style={{ width: "44vw" }}
                onChange={(e, timestring) => setInvoiceDate(timestring)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <h3 className="text-white">Invoice No</h3>
            <Form.Item
              name="invoiceNo"
              rules={[
                {
                  required: true,
                  message: "Select Invoice No",
                },
              ]}
            >
              <InputNumber placeholder="Invoice No" style={{ width: "44vw" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <h3 className="text-white">Purchaser Name</h3>
            <Form.Item
              name="purchaser"
              rules={[
                {
                  required: true,
                  message: "Enter purchaser name",
                },
              ]}
            >
              <Input placeholder="purchaser Name"  style={{ width: "44vw" }}/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <h3 className="text-white">Purchaser GSTIN</h3>
            <Form.Item
              name="purchaseGSTNr"
              rules={[
                {
                  required: true,
                  message: "Enter purchaser GSTN",
                },
              ]}
            >
              <Input placeholder="purchaser GSTN"  style={{ width: "44vw" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <h3 className="text-white">purchaser Address</h3>
            <Form.Item
              name="purchaserAddress"
              rules={[
                {
                  required: true,
                  message: "Enter purchaser Address",
                },
              ]}
            >
              <Input placeholder="purchaser Address"  style={{ width: "44vw" }}/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <h3 className="text-white">State</h3>
            <Form.Item
              name="purchaserState"
              rules={[
                {
                  required: true,
                  message: "Enter State",
                },
              ]}
            >
              <Input placeholder="State"  style={{ width: "44vw" }}/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <h3 className="text-white">Pin Code</h3>
            <Form.Item
              name="pinCode"
              rules={[
                {
                  required: true,
                  message: "Enter pin Code",
                },
              ]}
            >
              <InputNumber placeholder="Pin Code" style={{ width: "44vw" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <h3 className="text-white">Mobile No.</h3>
            <Form.Item
              name="mobileNo"
              rules={[
                {
                  required: true,
                  message: "Enter pMobile no.",
                },
              ]}
            >
              <InputNumber placeholder="Mobile no." style={{ width: "44vw" }} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <h3 className="text-white">CGST(%)</h3>
            <Form.Item name="cgst">
              <InputNumber placeholder="CGST" style={{ width: "20vw" }} />
            </Form.Item>
          </Col>

          <Col span={6}>
            <h3 className="text-white"> SGST(%)</h3>
            <Form.Item name="sgst">
              <InputNumber placeholder="SGST" style={{ width: "20vw" }} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <h3 className="text-white"> IGST(%)</h3>
            <Form.Item name="igst">
              <InputNumber placeholder="IGST" style={{ width: "20vw" }} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <h3 className="text-white">Discount(%)</h3>
            <Form.Item name="discount">
              <InputNumber placeholder="discount" style={{ width: "20vw" }} />
            </Form.Item>
          </Col>
        </Row>
      </div>
        
      <div className="md:px-14 ">
      <div  className="p-4 bg-cyan-900 opacity-90 rounded-b-md">
        <Form.List name="users" initialValue={[{}]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Row gutter={[12, 12]} >
                  <Col span={6} >
                    <Form.Item
                      {...restField}
                      name={[name, "hsnCode"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing first name",
                        },
                      ]}
                    >
                      <Input placeholder="HSN Code" style={{ width: "22vw" }}/>
                    </Form.Item>
                  </Col>
                  <Col span={6} >
                    <Form.Item
                      {...restField}
                      name={[name, "description"]}
                      rules={[
                        {
                          required: true,
                          message: "Product Name",
                        },
                      ]}
                    >
                      <Input placeholder="Product Name" style={{ width: "23vw" }}/>
                    </Form.Item>
                  </Col>
                  <Col span={3} style={{marginLeft:"12px"}}>
                    <Form.Item
                      {...restField}
                      name={[name, "quantity"]}
                      rules={[
                        {
                          required: true,
                          message: "Quantity ",
                        },
                      ]}
                    >
                      <InputNumber placeholder="Quantity" style={{ width: "10vw" }}/>
                    </Form.Item>
                  </Col>
                  <Col span={6} >
                    <Form.Item
                      {...restField}
                      name={[name, "price"]}
                      rules={[
                        {
                          required: true,
                          message: "Price ",
                        },
                      ]}
                    >
                      <InputNumber placeholder="Price" style={{ width: "16vw" }}/>
                    </Form.Item>
                  </Col>
                  <Col span={2} >
                  <Button  onClick={() => remove(name)} style={{ width: "2vw" }} >
                  <MinusCircleOutlined/>
                  </Button>
                    
                  </Col>
                </Row>
              ))}
              <div className="flex justify-center mt-3">
                <Form.Item>
                  <button
                    className="bg-cyan-700 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Product
                  </button>
                </Form.Item>
              </div>
            </>
          )}
        </Form.List>
        </div>
        </div>
        <div className="flex justify-center mt-3">
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{border:"1px solid gray" , color:"black", fontWeight:"bold"}}>
              Preview Invoice
            </Button>
          </Form.Item>
        </div>
      </Form>
      <Modal
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        width={900}
        className="JKL"
      >
        <div
          id="print-me"
          className="inline-block w-full md:w-210mm transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-xl transition-all "
        >
          <div className="bg-[#0a2632] md:h-40 h-28">
            <div className="p-1 flex justify-between">
              <div>
                <img src={logo} className="md:w-28 w-16 md:ml-10 ml-3" />
                <p className="md:text-lg text-[12px] text-[#ab7d49] font-extrabold md:-mt-5 -mt-2 md:ml-2">
                  Tanda rubber works
                </p>
              </div>

              <div className="p-2 md:pt-4 text-[#ab7d49]">
                <p className="md:text-sm text-[10px] text-[#ab7d49] -mb-0">
                  Behind Tanda tehsil near post office
                </p>
                <p className=" md:text-sm text-[10px] text-[#ab7d49] -mb-0">
                  Nehru Nagar, Tanda Ambedkar nagar
                </p>
                <p className="md:text-sm text-[10px] text-[#ab7d49] -mb-0">
                  Uttar Pradesh - 224190 INDIA
                </p>
                <p className="md:text-sm text-[10px] text-[#ab7d49] -mb-0">
                  Mobile: +91 6394750886
                </p>
                <p className="md:text-sm text-[10px] text-[#ab7d49] -mb-0">
                  Website: www.tandarubberworks.com
                </p>
              </div>
            </div>
           
          </div>
          <div className="px-1 bg-[#ab7d49]" >
          <div
            className="p-3 bg-neutral-200 relative" 
          >
           <img src={logo} alt="your-image-alt" className="md:w-460 w-300 md:ml-40 ml-2 opacity-20 md:mt-10 mt-14 fixed z-10"/>
            <div className="flex justify-between">
              <h4 className="text-left md:text-[13px] text-[9px] font-bold text-gray-900 -mt-2">
                Invoice No. &nbsp;: {invoiceData?.invoiceNo}
              </h4>
              <h4 className="text-left md:text-[13px] text-[9px] font-bold text-gray-900 -mt-2 ">
                GSTIN : 09CXQPA7585K1ZN
              </h4>
              <h4 className="text-left md:text-[13px] text-[9px] font-bold text-gray-900 -mt-2 ">
                Invoice Date.:{invoiceDate}
              </h4>
            </div>
            <div>
              <div className="bg-slate-300 md:mt-3 p-2 rounded-md">
                <div className="flex ">
                  <h2 className="md:text-[13px] text-[9px] font-bold md:w-1/6 w-1/4 ">Purchaser M/s</h2>
                  <h2 className="md:text-[13px] text-[9px] font-bold ">
                    : &nbsp; {invoiceData?.purchaser}
                  </h2>
                </div>

                <div className="flex">
                  <h2 className="md:text-[13px] text-[9px] font-bold md:w-1/6 w-1/4 ">Address</h2>
                  <h2 className="md:text-[13px] text-[9px] font-bold ">
                    : &nbsp; {invoiceData?.purchaserAddress}
                  </h2>
                </div>
                <div className="flex justify-between">
                  <div className="flex  w-1/2">
                    <h2 className="md:text-[13px] text-[9px] font-bold md:w-1/3 w-1/2">State</h2>
                    <h2 className="md:text-[13px] text-[9px] font-bold ">
                      : &nbsp; {invoiceData?.purchaserState}
                    </h2>
                  </div>

                  <div className="flex  w-1/2">
                    <h2 className="md:text-[13px] text-[9px] font-bold md:w-1/3 w-2/5">Code</h2>
                    <h2 className="md:text-[13px] text-[9px] font-bold  ">
                      : &nbsp; {invoiceData?.pinCode}
                    </h2>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="flex w-1/2">
                    <h2 className="md:text-[13px] text-[9px] font-bold md:w-1/3 w-1/2">Mobile No.</h2>
                    <h2 className="md:text-[13px] text-[9px] font-bold ">
                      : &nbsp; {invoiceData?.mobileNo}
                    </h2>
                  </div>
                  <div className="flex  w-1/2">
                    <h2 className="md:text-[13px] text-[9px] font-bold md:w-1/3 w-2/5">
                      GSTIN
                    </h2>
                    <h2 className="md:text-[13px] text-[9px] font-bold ">
                      : &nbsp; {invoiceData?.purchaseGSTNr}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6">
              <div className="mb-1">
                <Table
                  columns={columns}
                  dataSource={invoiceData?.users}
                  size="small"
                  bordered
                  pagination={false}
                />
              </div>
              {/* add table */}
              <div className="flex h-28 md:mt-5">
                <div className=" flex space-x-1 w-3/5  bg-gray-400 rounded-l-md p-1">
                  <div className="bg-white w-1/2 h-full ">
                    <div className="md:h-14 h-10">
                        <img src={sign} className="w-full ml-3 mt-3"/>
                    </div>
                    <h2 className="text-center -mb-0 font-bold md:text-xs text-[9px]">
                      TANDA RUBBER WORKS
                    </h2>
                    <h2 className="text-center font-bold md:text-xs text-[6px]">
                      (Authorised Signature)
                    </h2>
                  </div>
                  <div className="w-1/2  bg-white h-full md:p-3 ">
                    <h2 className="font-bold md:text-xs text-[7px] text-center">Bank Information</h2>
                    <h2 className="-mb-0 md:ml-0 ml-1  font-bold md:text-xs text-[8px]">
                      
                      Bank Name : HDFC
                    </h2>
                    <h2 className="-mb-0 md:ml-0 ml-1 font-bold md:text-xs text-[8px]">
                      
                      ACC no. : 50200053148601
                    </h2>
                    <h2 className="-mb-0 md:ml-0 ml-1 font-bold md:text-xs text-[8px]">
                      IFSC: HDFC0002663
                    </h2>
                    <h2 className="-mb-0 md:ml-0 ml-1 font-bold md:text-xs text-[8px]">
                      
                      Branch  : TANDA
                    </h2>
                  </div>
                </div>
                <div className="w-2/5  bg-slate-300 rounded-r-md pl-2">
                  
                  <div>
                    <div className="flex ">
                      <span className="font-bold  w-1/2 md:text-[12px] text-[9px]">Subtotal:</span>
                      <span className=" w-1/2 font-bold md:text-[12px] text-[9px]">Rs.{total}</span>
                    </div>
                    <div className="flex w-full md:-mt-1">
                      <span className="font-bold w-1/2 md:text-[12px] text-[9px]">Discount:</span>
                      <span className=" w-1/2 font-bold md:text-[12px] text-[9px]">
                        Rs.{discountAmmount}
                      </span>
                    </div>
                    <div className="flex w-full">
                      <span className="md:font-bold text-[9px]">CGST:</span>
                      <span className="ml-6 md:font-bold text-[9px]">
                        Rs.{cgstAmmount}
                      </span>
                    </div>
                    <div className="flex w-full">
                      <span className="md:font-bold text-[9px]">SGST:</span>
                      <span className="ml-6 md:font-bold text-[9px]">
                        Rs.{sgstAmmount}
                      </span>
                    </div>
                    <div className="flex w-full ">
                      <span className="md:font-bold text-[9px]">IGST:</span>
                      <span className="ml-6 md:font-bold text-[9px]">
                        Rs.{igstAmmount}
                      </span>
                    </div>
                    <div className="flex w-full ">
                      <span className="font-bold md:text-[12px] text-[9px] w-1/2">
                        Total Tax:
                      </span>
                      <span className="font-bold md:text-[12px] text-[9px] w-1/2">
                        Rs.{totalTax}
                      </span>
                    </div>
                    <div className="flex w-full justify-between border-t border-black/10 md:-mt-1">
                      <span className="font-bold md:text-[12px] text-[9px] w-1/2">Total:</span>
                      <span className="font-bold w-1/2 md:text-[12px] text-[9px]">
                        Rs.{(totalTax + total - discountAmmount)?.toFixed(2)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
     
          </div>
          </div>
          <div className="px-1 bg-[#ab7d49]">
          <div className="m-0 bg-neutral-200 rounded-b-md p-1 md:p-3">
          <p className=" md:text-xs text-[6px]">1. Losses in transit are at customer risk.</p>
            <p className="md:text-xs text-[6px]">2. Dispute of all nature will be settled in Ambedkar Nagar Law Court.</p>
            <p className="md:text-xs text-[6px]">3. Interest @ 2% will be charged in cash the payment is not made within 30 days from the date of bill.</p>
            <p className="md:text-xs text-[6px]">4. No claim kindly check the material before use.</p>
            
          </div>
          </div>
          
        <div className="w-full h-2 bg-[#ab7d49]"></div>
        <div className="w-full h-2 bg-[#0a2632]"></div>
        </div>
        <div className="flex justify-center">
          <Button type="primary" onClick={printDiv} style={{border:"1px solid gray" , color:"black", fontWeight:"bold"}}>
            <span>Download</span>
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Invoice;
