import React, { useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
import Logo from "../assets/tanda.png";
import "./Styles.css";
import Logo1 from "../assets/abcd.png";
import { NavLink } from "react-router-dom";

const MainHeader = () => {
  const [isMenu, setIsMenu] = useState(false);
  const [isUnderLine, setIsUnderLine] = useState(true);

  // const showMenu = () => {
  //   setIsMenu(!isMenu);
  // };

  // const myMenu = document.getElementById("menu");

  // myMenu.onclick = (
  //   e // JS event delegation
  // ) => {
  //   if (e.target.tagName.toLowerCase() != "a") return; // only
  //   e.preventDefault;
  //   let LI = e.target.parentElement;

  //   if (!LI.classList.contains("active")) {
  //     myMenu.querySelector("li.active").classList.remove("active");
  //   }
  //   LI.classList.add("active");
  // };

  return (
    <div className="fixed z-50 bg-[#0a2632] shadow-md h-24 md:h-24 px-0">
      {/* Desktop */}
      <div className="hidden md:flex w-screen  h-full">
        <div className=" w-full flex justify-between">
          <div className="flex -mt-1 ml-4" >
            <img src={Logo1} className="w-24 h-24" />
            <div className="text-[#ab7d49] mt-4"><p className="-mb-0  text-2xl font-extrabold font-serif">TANDA</p> <p className="font-serif">RUBBER WORKS</p> </div>
          </div>
          <div className="md:mr-14 md:mt-5 text-xl font-serif">
        <ul className="hidden md:flex justify-center space-x-10 px-3 mt-6 mr-5 uppercase ">
          <li className=" no-underline hover:underline decoration-slate-50 font-normal cursor-pointer">
            <NavLink
              to={`/`}
              style={{color:"white"}}
            >
              Home
            </NavLink>
          </li>
          <li className="no-underline hover:underline decoration-slate-50   font-normal cursor-pointer">
            <NavLink to={`/about-us`} style={{color:"white"}}>
              About us
            </NavLink>
          </li>
          <li className="no-underline hover:underline decoration-slate-50   font-normal cursor-pointer">
            <NavLink  to="/services" style={{ color: "white" }}>
            SERVICES
            </NavLink>
          </li>
      
          {/* 
          <li className="no-underline hover:underline  font-semibold cursor-pointer">
            <NavLink  to="/services" style={{ color: "black" }}>
            SERVICE SUPPORT
            </NavLink>
          </li>
          <li className="no-underline hover:underline  font-semibold cursor-pointer">
            <NavLink  to="/media" style={{ color: "black" }}>
              Media
            </NavLink>
          </li> */}
          <li className="no-underline hover:underline decoration-slate-50   font-normal cursor-pointer">
            <NavLink to="/contact-us" style={{color:"white"}} >
              Contact Us
            </NavLink>
          </li>
        </ul>
      </div>
          {/* <a
            href="https://wa.me/+918049720731"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center "
          >
            <div className="items-center rounded-l-3xl  bg-green-500 p-3  font-bold text-white md:h-9 h-10 flex	">
              <FaWhatsapp className="mx-2 text-3xl" />{" "}
              <span className="text-xl">+91 6364376522</span>
            </div>
            <div className="items-center  bg-blue-500   font-bold text-white md:h-9 h-10 flex pr-6	">
              <FiPhoneCall className="mx-2 text-2xl" />
              <span className="text-xl">+91 8049720731</span>
            </div>
          </a> */}
        </div>
        
      </div>
     

      {/* mobile */}
      <div className=" md:hidden  ">
        <div className="flex w-screen p-2 h-full justify-between ">
          <div className="flex">
            <img src={Logo1} className="w-24 h-24" />
             <div className="text-[#ab7d49] mt-4 ml-4"><p className="-mb-0 text-2xl font-extrabold font-serif">TANDA</p> <p className="font-serif">RUBBER WORKS</p> </div>
          </div>
          <button
            className="-mt-5 block md:hidden py-3 px-4 mx-2 rounded focus:outline-none hover:bg-gray-900 group h-20"
            onClick={() => setIsMenu(!isMenu)}
          >
            <div className="w-5 h-1 bg-white mb-1"></div>
            <div className="w-5 h-1 bg-white mb-1"></div>
            <div className="w-5 h-1 bg-white"></div>
          </button>
          {isMenu && (
            <div
              className="absolute top-24 w-full right-0
      group-focus:right-0 group-focus:opacity-100 transition-all duration-300 bg-gray-800  
       shadow-lg shadow-black-500/110"
            >
              <ul className="flex flex-col w-full text-base cursor-pointer pt-1 text-left uppercase">
                <li
                  className=" ml-2 py-2 transition ease-in-out delay-50 font-semibold  hover:-translate-y-4 hover:scale-10
                hover:bg-blue-400  duration-300 ..."
                >
                  <NavLink
                    onClick={() => setIsMenu(false)}
                    to={`/`}
                    style={{
                      // textDecoration: isUnderLine ? "underline" : "",
                      color: "white",
                    }}
                  >
                    Home
                  </NavLink>
                </li>
                <li className="hover:bg-gray-200 ml-2 py-2 transition ease-in-out delay-50 font-semibold  hover:-translate-y-1 hover:scale-10 duration-300 ...">
                  <NavLink
                    onClick={() => setIsMenu(false)}
                    to={`/about-us`}
                    style={{ color: "white" }}
                  >
                    About us
                  </NavLink>
                </li>
                <li className="hover:bg-gray-200 ml-2 py-2 transition ease-in-out delay-50 font-semibold  hover:-translate-y-1 hover:scale-10 duration-300 ...">
                  <NavLink
                    onClick={() => setIsMenu(false)}
                    to="/services"
                    style={{ color: "white" }}
                  >
                    SERVICES
                  </NavLink>
                </li>
                <li className="hover:bg-gray-200 ml-2 py-2 transition ease-in-out delay-50 font-semibold  hover:-translate-y-1 hover:scale-10 duration-300 ...">
                  <NavLink
                    onClick={() => setIsMenu(false)}
                    to="/contact-us"
                    style={{ color: "white" }}
                  >
                    Contact Us
                  </NavLink>
                </li>
              </ul>
            </div>
          )}
        </div>
        {/* <div>
          <a
            href="https://wa.me/+918049720731"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="items-center  bg-green-500 p-3 font-bold text-white h-10 flex	">
              <FaWhatsapp size={20} /> &nbsp; +91 6364376522
            </div>
          </a>

          <div className="items-center  bg-blue-500  p-3 font-bold text-white h-10 flex	">
            <FiPhoneCall size={17} /> &nbsp; +91 8049720731
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default MainHeader;
