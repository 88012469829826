import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import back from "../../assets/background.jpg";
import back1 from "../../assets/bussiness.jpg";
import "./Styles.css"



import { Button, Card, Checkbox, Col, Form, Input, Row } from "antd";

const ContactUs = () => {
  // const [form] = Form.useForm();
  const formRef = useRef();
  
  const onFinish = (values) => {
    console.log("Success:", values);


    emailjs.send('service_4rt9kj8', 'template_23q0z5i', values , 'bYQXRsUBqDumNdiu-',{ form: formRef.current })
    .then((result) => {
        console.log(result);
        formRef.current.resetFields();
    }, (error) => {
        console.log(error);
    });
  
  };

const myStyle={
        backgroundImage:  `url(${back1})`,
        height:'100vh',
        marginTop:'-70px',
        fontSize:'50px',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    };
 
  return (
    <div style={myStyle} >
    {/* <Col span={7} ><img src={imageIcon} style={{height:"45vw"}} /></Col> */}
    <Col  className='mt-24 md:mt-[33px] ml-[20px] md:ml-14 '
     style={{display:"flex", justifyContent:"left", marginRight:"20px"}}
      >
    <div>
    <div className='text-2xl font-bold text-slate-50 text-center mb-6 mt-5'>
      
        Got Questions....?
      
      <br/>
        Lets figure this out together!
      
      </div>
      <div className='bg-teal-700 rounded-lg px-10 py-5 bg-opacity-40'> 
      <Form
       ref={formRef}
      id="contact-form" 
      name="basic"
      labelCol={{
        span: 24
      }}
      wrapperCol={{
        span: 24
      }}
      initialValues={{
        remember: true
      }}
      onFinish={(values, form) => onFinish(values, form)}
      className="text-white"
    >
      <Form.Item label="Your Name" name="user_name">
        <Input />
      </Form.Item>
      <Form.Item
        name="user_email"
        label="Your Email address"
        rules={[
          {
            type: "email"
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="user_number" label="Your Contact Number">
        <Input />
      </Form.Item>
      <Form.Item name="message" label="Message">
        <Input.TextArea />
        
      </Form.Item>
      
      <Form.Item>
      <Row style={{backgroundColor:"#0b7cc2",justifyContent:"center", borderRadius:"12px"}}>
      <Button style={{backgroundColor:"#0b7cc2", color:"white", border:"none"}} htmlType="submit">
          Submit
        </Button>
      </Row>
        
      </Form.Item>
      
     
    </Form></div>
    
    </div>
    </Col>
    
   
      
    </div>
  )
}

export default ContactUs