
import React,{useState} from "react";
import owner from "../../assets/owner.jpeg";
import Background2 from "./aboutImg/aboutUS.png";
import Background5 from "./aboutImg/bbg.jpg";
import Background1 from "./aboutImg/about22.png";
import Background3 from "./aboutImg/backkk.png";
import { Form, Input, Button, Modal, } from 'antd';
import {useNavigate } from "react-router-dom";




const AboutUs = () => {
  const navigate = useNavigate();
  const onFinish = (values) => {
    console.log('Received values of form: ', values);

    if (values.numberInput === '264962') { 
      navigate("/invoice-desk");
    } else {
      alert('Wrong number!'); 
    }
  };
 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      {/* <div
        className="bg-cover bg-center md:-mt-8 -mt-24 h-[90vh] md:p-9 p-7"
        style={{ backgroundImage: `url(${Background2})` }}
      >
        <div className="container mx-auto bg-black bg-opacity-50 text-white p-4 rounded-3xl md:mt-28 mt-52 md:ml-[37vw] ml-[10vw] md:w-[50vw] w-[80vw]">
          <h1 className="text-white md:text-5xl text-2xl  font-bold tracking-tight ">
            Tanda Rubber Works
          </h1>
          <p className="text-white md:text-lg text-sm mt-4">
            TANDA rubber WORKS is a leading supplier for different kind of Rice
            mill polisher rubber, Rubber sheet for flooring, Conveyor belt,
            Rubber blanket and Natural Rubber pad. TANDA rubber WORKS Operate
            PAN India and its products and services are available across all
            over India. TANDA rubber WORKS has selected its portfolio carefully
            to ensure that we can offer best product that matches with your
            project requirement and the budget. TANDA rubber WORKS supply and
            maintain very heavy duty industrial Rubber products.{" "}
          </p>
        </div>
      </div> */}
      <div
        className="bg-cover bg-center md:-mt-8 -mt-24 md:h-[60vh] h-[70vh] md:p-9 p-7"
        style={{ backgroundImage: `url(${Background1})` }}
      >
      
        <div className="container mx-auto bg-black bg-opacity-50 text-white p-4 rounded-3xl md:mt-10 mt-28 md:ml-[37vw]  md:w-[50vw] w-[80vw]">
          <h1 className="text-white md:text-5xl text-xl  font-bold tracking-tight ">
            Our Mission
          </h1>
          <p className="text-white md:text-lg text-sm mt-4">
            To provide essential value to your supply chain in order to advance
            and overcome the obstacles of doing business with different
            Industries. We approach each relationship with a view to creating
            long-term and sustained partnerships built on the shared values of
            trust and consistency
          </p>
        </div>
      </div>
      <div 
        className="bg-cover bg-center md:-mt-8 -mt-7 md:h-[60vh] h-[40vh] md:p-9 p-7"
        style={{ backgroundImage: `url(${Background5})` }}
      >
      <div className="container mx-auto bg-black bg-opacity-75 text-white p-4 rounded-3xl md:w-[90vw] md:px-10">
      <h1 className=" text-orange-600 md:text-5xl text-lg  font-bold tracking-tight text-center">
      Tanda Rubber Works
          </h1>
          <p className="text-white md:text-2xl font-serif text-center text-sm mt-4  overflow-auto
	[@media(max-width:767px)]:scrollbar-hide md:h-[27vh] h-80 ">
          TANDA rubber WORKS is a leading supplier for different kind of Rice
            mill polisher rubber, Rubber sheet for flooring, Conveyor belt,
            Rubber blanket and Natural Rubber pad. TANDA rubber WORKS Operate
            PAN India and its products and services are available across all
            over India. TANDA rubber WORKS has selected its portfolio carefully
            to ensure that we can offer best product that matches with your
            project requirement and the budget. TANDA rubber WORKS supply and
            maintain very heavy duty industrial Rubber products.
          </p>
      </div>
        
      </div>
      <div
        className="bg-cover bg-center md:-mt-8 -mt-7 h-[50vh] md:p-9 p-7 flex"
        style={{ backgroundImage: `url(${Background3})` }}
      >
      <div className="md:flex container mx-auto bg-black bg-opacity-50 text-white p-4 rounded-3xl md:w-[90vw] md:h-300 h-340">
      <div className="bg-slate-500 bg-opacity-50 rounded-full p-2  md:w-48 w-20  md:ml-16" >
      <img src={owner} className="rounded-full md:w-48 w-20  shadow-lg" />
      </div>
       
       <div className="md:w-[50vw] md:ml-16">
       <p className="md:text-2xl text-sm font-extrabold font-serif md:mt-10 md:ml-6">Shakheel Ahmad</p>
        <p className="md:text-lg text-xs font-serif md:ml-6">Founder of TANDA RUBBER WORKS</p>
        <p className="md:text-lg text-xs font-serif  md:ml-6">I always believe firstly on product quality not on quantity, because that quality of product will make me to produce more quantity. Truily said “Don’t find customers for your products, find products for your customers.”</p>
        {/* <Button  className="md:text-lg text-xs font-serif  md:ml-6" href="/invoice-desk">Click Here For Login</Button> */}
       <div className="md:ml-6">
       <Form onFinish={onFinish} >
       <div className="flex">
        <div className="w-20"> <Form.Item name="numberInput" >
        <Input type="number" />
      </Form.Item></div>
        <div><Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item></div>
       </div>
      
      
    </Form>
    </div>
        
       
    </div>
       </div>
     
        {/* <div className="container mx-auto bg-black bg-opacity-50 text-white p-4 rounded-3xl md:mt-28 mt-52 md:ml-[37vw] ml-[10vw] md:w-[50vw] w-[80vw]">
          <h1 className="text-white md:text-5xl text-2xl  font-bold tracking-tight ">
            Our Mission
          </h1>
          <p className="text-white md:text-lg text-sm mt-4">
            To provide essential value to your supply chain in order to advance
            and overcome the obstacles of doing business with different
            Industries. We approach each relationship with a view to creating
            long-term and sustained partnerships built on the shared values of
            trust and consistency
          </p>
        </div> */}
      </div>
      <Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
      
      </Modal>
    </>
  );
};

export default AboutUs;
