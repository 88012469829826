import React from "react";
// import logo from "../assets/logo.png";
import {useNavigate } from "react-router-dom";

import { FaWhatsapp, FaLinkedinIn } from "react-icons/fa";
import { FiFacebook, FiTwitter,FiPhoneCall } from "react-icons/fi";
import "./Styles.css";

export const Footer = () => {
  const navigate = useNavigate();
  // useEffect(()=>{
  //   setWindowSize(window.innerWidth)
  //   console.log(windowSize);
  // },[window.innerWidth])

  return (
    <div className="h-32 md:h-48 bg-gray-900 shadow-md px-5 py-5">
      {window.innerWidth <= 768 ? (
        <></>
      ) : (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="invisible md:visible">
            <p className="text-slate-300 text-2xl font-bold">
              Tanda rubber works{" "}
            </p>
            <p className="text-base text-slate-300 ">
              {" "}
              Behind Tanda tehsil near post office{" "}
            </p>
            <p className="text-base text-slate-300 ">
              Nehru Nagar, Tanda Ambedkar nagar
            </p>
            <p className="text-base text-slate-300">
              Uttar Pradesh - 224190 INDIA
            </p>
            <p className="text-base text-slate-300">
              Mobile: +91 6394750886
            </p>
            {/* <p className="text-base text-slate-300 -mt-3">
          shakeel.ahmad@tandarubberworks.com <br />
            www.tandarubberworks.com
          </p> */}
          </div>

          <div className="invisible md:visible">
            <ul
              style={{
                // fontSize: "12px",
                display: "block",
                lineHeight: 1.5,
                letterSpacing: "2px",
              }}
              className="uppercase text-slate-300 cursor-pointer text-lgn font-semibold "
            >
             <li
                className="cuseorPointer hover:text-blue-400"
                onClick={() => {
                  navigate("/");
                }}
              >
                Home
              </li>
              <li
                className="cuseorPointer hover:text-blue-400"
                onClick={() => {
                  navigate("/services");
                }}
              >
                Services
              </li>
              <li
                className="cuseorPointer hover:text-blue-400"
                onClick={() => {
                  navigate("/about-us");
                }}
              >
                About Us
              </li>
              
              <li
                className="cuseorPointer hover:text-blue-400"
                onClick={() => {
                  navigate("/contact-us");
                }}
              >
                Contact Us
              </li>
            </ul>
          </div>
          <ul
            style={{
              fontSize: "12px",
              display: "block",
              lineHeight: 1.5,
              letterSpacing: "2px",
              marginRight: "10px",
            }}
            className="uppercase text-slate-300"
          >
            {" "}
            <li>
              <div className="flex justify-between">
                {/* <a
                  href="https://www.facebook.com/people/Shakeel-Ahamad/pfbid024Y76a5SJj8Enc4FPchYzcmQ4Ps5g1MyBgUiBajEFAjY3ECXhYtYNkWqDtJGrDy9sl/"
                  target="_blank"
                  className=" w-12 h-12 bg-sky-500 hover:bg-sky-200 pt-2 px-2 rounded-md mx-2"
                >
                  <FiFacebook className="fill-white w-8 h-8" />
                </a> */}

                <a
                  href="https://wa.me/+916394750886"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-12 h-12 bg-white hover:bg-sky-200 pt-2 px-2 rounded-md mx-2"
                >
                  <FaWhatsapp className="fill-green-500  w-8 h-8" />
                </a>
                {/* <a href="https://twitter.com/Tanda2020"
                  target="_blank" className="w-12 h-12 bg-white hover:bg-sky-200 pt-2 px-2 rounded-md mx-2">
                  <FiTwitter className="fill-sky-500  w-8 h-8" />
                </a>

                <a href="https://www.linkedin.com/in/tanda-rubber-works-117b4a26a/"
                  target="_blank" className="w-12 h-12 bg-sky-500 hover:bg-sky-200 pt-2 px-2 rounded-md mx-2">
                  <FaLinkedinIn className="fill-white w-8 h-8 " />
                </a> */}
              </div>
            </li>
          </ul>
        </div>
      )}
      {window.innerWidth <= 768 ? (
        <div className="flex justify-between">
          <ul
            style={{
              fontSize: "12px",
              display: "block",
              lineHeight: 1.5,
              letterSpacing: "2px",
            }}
            className="uppercase text-slate-300"
          >
          <li
              className="cuseorPointer"
              onClick={() => {
                navigate("/");
              }}
            >
              Home
            </li>
            <li
              className="cuseorPointer"
              onClick={() => {
                navigate("/services");
              }}
            >
              Services
            </li>
            <li
              className="cuseorPointer"
              onClick={() => {
                navigate("/about-us");
              }}
            >
              About Us
            </li>
            <li
              className="cuseorPointer"
              onClick={() => {
                navigate("/contact-us");
              }}
            >
              Contact Us
            </li>
          </ul>
          <ul
            style={{
              fontSize: "12px",
              display: "block",
              lineHeight: 1.5,
              letterSpacing: "2px",
            }}
            className="uppercase text-slate-300"
          >
            {" "}
            <li>
              <div className="flex justify-between">
                {/* <a
                  href="https://www.facebook.com/people/Shakeel-Ahamad/pfbid024Y76a5SJj8Enc4FPchYzcmQ4Ps5g1MyBgUiBajEFAjY3ECXhYtYNkWqDtJGrDy9sl/"
                  target="_blank"
                  className=" w-7 h-7 bg-sky-500 hover:bg-sky-200 pt-2 px-2 rounded-md mx-2"
                >
                  <FiFacebook className="fill-white" />
                </a> */}

                <a href="https://wa.me/+916394750886"
                  target="_blank"
                  rel="noopener noreferrer" className="w-7 h-7 bg-white hover:bg-sky-200 pt-2 px-2 rounded-md mx-2">
                  <FaWhatsapp className="fill-green-500" />
                </a>
                {/* <a href="https://twitter.com/Tanda2020"
                  target="_blank" className="w-7 h-7 bg-white hover:bg-sky-200 pt-2 px-2 rounded-md mx-2">
                  <FiTwitter className="fill-sky-500" />
                </a>

                <a
                  href="https://www.linkedin.com/in/tanda-rubber-works-117b4a26a/"
                  target="_blank"
                  className="w-7 h-7 bg-sky-500 hover:bg-sky-200 pt-2 px-2 rounded-md mx-2"
                >
                  <FaLinkedinIn className="fill-white " />
                </a> */}
              </div>
            </li>
          </ul>
        </div>
      ) : (
        <></>
      )}

      <p
        style={{
          display: "flex",
          justifyContent: "center",
          fontSize: "11px",
          color: "white",
          textAlign: "center",
        }}
      >
        Copyright © 2023 TANDA RUBBER WORKS - All Rights Reserved.
      </p>
    </div>
  );
};
