import React from "react";
import { Route, Routes } from "react-router-dom";
import { MainHeader } from "./common/index";
import Home from "./pages/home/Home";
import AboutUs from "./pages/aboutUs/AboutUs";
import ContactUs from "./pages/contactUs/ContactUs";
import {FiPhoneCall } from "react-icons/fi";



import { Footer } from "./common/Footer";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Services from "./pages/services/Services";
import { Button } from "antd";
import Invoice from "./pages/invoice/Invoice";

const App = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  function dialPhoneNumber() {
    window.location.href = 'tel:639-475-0886'; // replace with the phone number you want to dial
  }
  return (
    <div className="relative h-auto flex flex-col bg-slate-50 overflow-x-scroll scrollbar-none ">
      <MainHeader />
      {/* <HeaderWithComponent /> */}
      <main className="md:mt-[110px] mt-[70px] ">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
         
          <Route path="/services" element={<Services />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/invoice-desk" element={<Invoice />} />
        </Routes>
     
      </main>
      <div onClick={dialPhoneNumber} className="z-50 fixed top-[80vh]  md:right-10 right-1 rounded-full p-6 bg-yellow-400"><span className=" animate-ping absolute inline-flex h-14 w-14 rounded-full bg-sky-700 opacity-100 q qq -ml-2 -mt-2"></span><FiPhoneCall onClick={dialPhoneNumber} className=" text-black text-4xl font-extrabold  "/></div>
      <Footer />
    </div>
  );
};

export default App;
