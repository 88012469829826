import React from "react";
import { Button, Carousel, Col, Row } from "antd";

import "./Scroll.css";
import image1 from "./homeImage/Conveyorbelt.jpg"
import image2 from "./homeImage/rubber.jpeg"
import image3 from "./homeImage/bghome.png"
import image4 from "./homeImage/Rubberblanket.jpg"
import image5 from "./homeImage/c1.webp"
import image6 from "./homeImage/c1.webp"
import image7 from "./homeImage/c2.webp"
import image8 from "./homeImage/c3.webp"
import image9 from "./homeImage/image1.webp"
import image10 from "./homeImage/image2.webp"
import image11 from "./homeImage/image3.jpg"
import image12 from "./homeImage/image4.webp"
// import a1 from "./homeImage/a1.jpeg"
// import a2 from "./homeImage/a2.jpeg"
// import a3 from "./homeImage/a3.jpeg"
import a5 from "./homeImage/a5.jpeg"
import a6 from "./homeImage/a6.jpeg"
// import a7 from "./homeImage/a7.jpeg"

import a1 from "./homeImage/i1.jpeg"
import a2 from "./homeImage/i2.jpeg"
import a3 from "./homeImage/i3.jpeg"
import a7 from "./homeImage/i4.jpeg"





import { useNavigate } from "react-router-dom";

const Home = () => {

  let navigate = useNavigate();
  const BannerImg = [
    // { id: 3, title: "title1", image: image1 },
    { id: 4, title: "title1", image: image5 },
    { id: 5, title: "title1", image: image7 },
    { id: 6, title: "title1", image: image6 },
    // { id: 7, title: "title1", image: image4 },
    { id: 8, title: "title1", image: image9 },
    { id: 9, title: "title1", image: image10 },
    // { id: 10, title: "title1", image: image3 },
    { id: 11, title: "title1", image: image8 },
    { id: 12, title: "title1", image: image11 },
    { id: 13, title: "title1", image: image12 },
  ];

  return (
   <div >
  
   
      <Row >
      <Col  xl={{ span: 12 }}
          lg={{ span: 12 }}
          md={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }} className=" flex md:h-[50vh] h-[35vh] mt-8 md:px-1 px-9">
      <img src={image2} className="md:h-[50vh] h-[35vh] md:ml-8"/>
      </Col>
      
      <Col  xl={{ span: 12 }}
          lg={{ span: 12 }}
          md={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }} className="md:p-16 p-6">
      <p className=" md:text-5xl text-3xl text-gray-800 font-extrabold md:ml-10 text-center">Understand  Customer Better than anyone </p>
      <p className=" animate-bounce md:text-5xl text-3xl  bg-gradient-to-r from-cyan-400 via-teal-800 to-stone-700  text-transparent bg-clip-text font-extrabold md:ml-10 text-center md:mt-6 mt-10">TANDA RUBBER WORKS </p>
      <p className="md:text-xl text-lg text-center text-gray-800 font-bold md:ml-10 md:mt-8">Get the best Product with High Quality</p>
      
      </Col>
     
      </Row>
      <div className="px-4 bg-slate-400 bg-opacity-25">
      <div className="flex overflow-x-scroll scrollbar-none mt-4">
        <div className="slider">
          <div className="slide-track ">
            {BannerImg?.map((item) => (
              <div className="slide ">
                <div
                  // onClick={() => navigate("/cleaning-pest-control-equipmet")}
                >
                  <img className="img__img" src={item?.image} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      </div>
    
      <section className="overflow-hidden text-neutral-700 my-4 bg-slate-500">
      <h1 className="text-3xl text-white font-serif font-bold ml-3 mt-2 md:-mb-2 text-center">Our Product</h1>
  <div className="container mx-auto px-5 py-2 lg:px-32 lg:pt-12">
    <div className="-m-1 flex flex-wrap md:-m-2">
      <div className="flex w-1/3 flex-wrap">
        <div className="w-full p-1 md:p-2">
          <img
            alt="gallery"
            className="block h-32 md:h-72 w-full rounded-lg object-cover object-center"
            src={a1}/>
        </div>
      </div>
      <div className="flex w-1/3 flex-wrap">
        <div className="w-full p-1 md:p-2">
          <img
            alt="gallery"
            className="block h-32 md:h-72 w-full rounded-lg object-cover object-center"
            src={a2}/>
        </div>
      </div>
      <div className="flex w-1/3 flex-wrap">
        <div className="w-full p-1 md:p-2">
          <img
            alt="gallery"
            className="block  h-32 md:h-72 w-full rounded-lg object-cover object-center"
            src={a3} />
        </div>
      </div>
      <div className="flex w-1/3 flex-wrap">
        <div className="w-full p-1 md:p-2">
          <img
            alt="gallery"
            className="block h-32 md:h-72 w-full rounded-lg object-cover object-center"
            src={a5} />
        </div>
      </div>
      <div className="flex w-1/3 flex-wrap">
        <div className="w-full p-1 md:p-2">
          <img
            alt="gallery"
            className="block h-32 md:h-72 w-full rounded-lg object-cover object-center"
            src={a6} />
        </div>
      </div>
      <div className="flex w-1/3 flex-wrap">
        <div className="w-full p-1 md:p-2">
          <img
            alt="gallery"
            className="block h-32  md:h-72 w-full rounded-lg object-cover object-center"
            src={a7} />
        </div>
      </div>
    </div>
  </div>
</section>

   </div>
  );
};

export default Home;
