import React from "react";
import RicePub from "./images/image2.jpeg";
import Rice from "./images/image3.webp";
import Roller from "./images/rubbeROle.webp";
import Rol from "./images/images55.png";

const Services = () => {
  return (
    <div className="md:p-5 p-10">
      <div>
        <h2 className="md:text-3xl text-3xl font-bold font-serif text-cyan-700 md:ml-4">
          Rice Rubber Polisher Brakes
        </h2>
        <div className="md:flex">
          <div className="md:w-[40vw]  md:-mt-7">
            <img src={RicePub} className="w-96 md:m-11" />
          </div>
          <div className="md:p-10 md:text-xl md:w-[60vw]">
            <p>
              Tanda Rubber Works provide best Rice Rubber Polisher brakes to the
              this modern market.
            </p>
            <p>
              Rice Rubber Polisher brakes are important part of modern rice
              milling industry. To polish rice all rice mills need large
              quantity of rice rubber polisher sticks ( brakes) on regular
              bases.
            </p>
            <p>
              Rice Rubber Polisher are basically rectangular shaped long sticks
              (aka Rice rubber polisher brakes) and are generally made by
              natural rubber and Styrene-butadiene rubber as its basic raw
              material.
            </p>
          </div>
        </div>
        <div className="md:flex">
          <div className="md:p-10 md:text-xl md:w-[60vw]">
            <p>
              A good rubber polisher will help in making the most of the quality
              of the grains and also increases it in the process.It helps in
              performing many processes and polishes rice in the best manner.
              With the help of a polisher, you can easily go for the cleaning of
              the rice properly and enjoying the good grains produced in the
              process.
            </p>
            <h1>Rice Rubber Polisher Sticks Sizes</h1>
            <div className="flex">
              <div className="md:mr-10 mr-16">
                {" "}
                <p className="text-sm">1. 16 ✖ 1½ ✖ ½</p>
                <p className="text-sm">2. 18 ✖ 1½ ✖ 1½</p>
                <p className="text-sm">3. 20 ✖ 2 ✖ 2</p>
                <p className="text-sm">4. 18 ✖ 2 ✖ 2</p>
              </div>
              <div>
                <p className="text-sm">5. 18 ✖ 1½ ✖ 2</p>
                <p className="text-sm">6. 22 ✖ 2 ✖ 2</p>
                <p className="text-sm">7. 24 ✖ 2 ✖ 2</p>
              </div>
            </div>
          </div>
          <div className="md:w-[40vw] md:p-10  md:-mt-7">
            <img src={Rice} className="md:mt-24 md:w-[50vw] " />
          </div>
        </div>
      </div>
      <div>
        <h2 className="md:text-3xl text-3xl font-bold font-serif text-cyan-700 md:ml-4 md:mt-7 mt-10">
          Rice Rubber Roller:
        </h2>
        <div className="md:flex">
          <div className="md:w-[40vw]">
            <img src={Roller} className="md:w-340 md:ml-16" />
          </div>
          <div className="md:w-[60vw] md:p-10 md:text-xl">
            Rice Rubber Roller is used in rice mills for separating husk from
            rice. We are delivered all types of paddy dehusking rubber rolls.
            The rubber roll for the rice mill comes in many different sizes.
            These type of rice mill rubber roll are till date best selling model
            everywhere in the world. These type of rolls drums are made of cast
            iron, mild steel, and best selling model aluminum drums. These type
            of rolls have the flame in the middle of the drum. We are providing
            an aluminum drum in a home which has excellent strength compared to
            drum available in the market.
          </div>
        </div>
        <div className="md:flex">
          <div className="md:w-[60vw] md:p-10 md:text-xl">
            The rice rubber roller should be constructed of a durable material
            that can be selected for its oil and resistance properties. This
            gives a better yield and output of the material processed. A
            compounded rubber material provides
            <div className="md:mr-10 mr-16 md:text-xl">
                <p className="md:text-lg -mb-1">1. The Roller With Strength</p>
                <p className="md:text-lg -mb-1">2. Resiliency</p>
                <p className="md:text-lg -mb-1">3. Temperature Resistance</p>
                <p className="md:text-lg -mb-1">4. Durability, Flexibility</p>
                <p className="md:text-lg -mb-1">4. The Capacity To Withstand All The Mechanical Stress And Forces</p>
              </div>
          </div>
          <div className="md:w-[40vw]">
            <img src={Rol} className="md:w-340 md:ml-16" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
